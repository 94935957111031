<common-confirm></common-confirm>

<div class="row flex">
    <div class="min padding-right-20" style="padding-top: 7px">
        <h3 class="mat-h3">Service levels</h3>
    </div>
    <div class="min">
        <mat-checkbox [formControl]="onlyActive">Only active</mat-checkbox>
    </div>
</div>

<div *ngIf="serviceLevels.length < 1">
    No any data
    <br>
    <br>
</div>
<mat-accordion>
    <ng-container *ngIf="serviceLevels.length > 0">
        <ng-template ngFor let-serviceLevel let-i="index" [ngForOf]="serviceLevels">
            <mat-expansion-panel *ngIf="serviceLevel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>
                            {{ serviceLevel.value.name }}
                        </b>
                        &nbsp;
                        <ng-template ngFor let-co [ngForOf]="coverages">
                            <span *ngIf="co.value === serviceLevel.value.coverage_id">
                                ({{ co.name }})
                            </span>
                        </ng-template>

                        <span *ngIf="!serviceLevel.value.is_active" class="text-error">
                            &nbsp;| Inactive
                        </span>
                    </mat-panel-title>

                    <mat-panel-description>
                        <ng-template ngFor let-ot [ngForOf]="orderTypes">
                            <span *ngIf="ot.value === serviceLevel.value.order_type_id">
                                {{ ot.name }} /
                            </span>
                        </ng-template>

                        <ng-template ngFor let-sm [ngForOf]="shippingModes">
                            <span *ngIf="sm.value === serviceLevel.value.shipping_mode_id">
                                {{ sm.name }} /
                            </span>
                        </ng-template>
                        <ng-template ngFor let-cs [ngForOf]="courierServices">
                            <span *ngIf="cs.value === serviceLevel.value.courier_service_id">
                                {{ cs.name }}
                            </span>
                        </ng-template>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <form [formGroup]="serviceLevel" autocomplete="off" (submit)="updateServiceLevel(i)">
                        <div class="group flex margin-bottom-0">
                            <div class="full">
                                <mat-checkbox formControlName="is_active">Active</mat-checkbox>
                            </div>

                            <mat-form-field class="full">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="name" required>
                            </mat-form-field>

                            <mat-form-field class="full">
                                <mat-label>Description</mat-label>
                                <input matInput formControlName="description" required>
                            </mat-form-field>

                            <mat-form-field class="full">
                                <mat-label>Partner service level</mat-label>
                                <mat-select formControlName="coverage_id" required>
                                    <mat-option>None</mat-option>
                                    <mat-option *ngFor="let coverage of coverages" [value]="coverage.value">
                                        {{ coverage.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="half">
                                <mat-label>Order type</mat-label>
                                <mat-select formControlName="order_type_id" required>
                                    <mat-option>None</mat-option>
                                    <mat-option *ngFor="let type of orderTypes" [value]="type.value">
                                        {{ type.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="half">
                                <mat-label>Notifications team</mat-label>
                                <mat-select formControlName="team_id">
                                    <mat-option>None</mat-option>
                                    <mat-option *ngFor="let team of teams" [value]="team.value">
                                        {{ team.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="full">
                                <mat-label>Courier service</mat-label>
                                <mat-select formControlName="courier_services" required
                                            [multiple]="true"
                                            [disableOptionCentering]="false">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="selectSearch"
                                                               [placeholderLabel]="'Search'"
                                                               [clearSearchInput]="true"
                                                               [noEntriesFoundLabel]="'No items found'">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let option of courierServicesFiltered | async"
                                                [value]="option.value">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="full margin-bottom-10">
                                <button (click)="linkHubs(serviceLevel.value.id)" type="button" mat-raised-button
                                        color="accent">
                                    Link courier services to hubs
                                </button>
                            </div>

                            <mat-form-field class="full">
                                <mat-label>Courier service default</mat-label>
                                <mat-select formControlName="courier_service_id"
                                            required>
                                    <mat-option *ngFor="let id of serviceLevel.value.courier_services" [value]="id">
                                        {{ courierServicesNames[id] }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="full">
                                <mat-label>Invoice remark</mat-label>
                                <textarea matInput formControlName="invoice_remark"></textarea>
                            </mat-form-field>

                            <common-form-fields class="full" [fields]="customFields" #cF
                                                [values]="serviceLevel.value.properties"
                                                (valueChange)="updateCustomFields(serviceLevel,$event)">
                            </common-form-fields>

                            <button [disabled]="!serviceLevel.valid || !cF.formGroup.valid"
                                    mat-raised-button
                                    color="primary" type="submit">
                                Update
                            </button>

                            <button mat-raised-button color="warn" class="delete-button"
                                    type="button" (click)="submitDelete(i)">
                                Delete
                            </button>
                        </div>
                    </form>
                </ng-template>
            </mat-expansion-panel>
        </ng-template>
    </ng-container>

    <mat-expansion-panel *ngIf="newForm">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <button type="button" mat-raised-button color="accent"
                        (click)="amplitudeClick('Add service level')">
                    Add
                </button>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
            <form [formGroup]="newForm" autocomplete="off" (submit)="submitNewServiceLevel()">
                <div class="group flex margin-bottom-0">
                    <div class="full">
                        <mat-checkbox formControlName="is_active">Active</mat-checkbox>
                    </div>

                    <mat-form-field class="full">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" required>
                    </mat-form-field>

                    <mat-form-field class="full">
                        <mat-label>Description</mat-label>
                        <input matInput formControlName="description" required>
                    </mat-form-field>

                    <mat-form-field class="full">
                        <mat-label>Partner service level</mat-label>
                        <mat-select formControlName="coverage_id" required>
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let coverage of coverages" [value]="coverage.value">
                                {{ coverage.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Order type</mat-label>
                        <mat-select formControlName="order_type_id" required>
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let type of orderTypes" [value]="type.value">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="half">
                        <mat-label>Notifications team</mat-label>
                        <mat-select formControlName="team_id">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let team of teams" [value]="team.value">
                                {{ team.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                        <mat-label>Courier service</mat-label>
                        <mat-select formControlName="courier_services" required
                                    [multiple]="true"
                                    [disableOptionCentering]="false">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="selectSearch" [placeholderLabel]="'Search'"
                                                       [clearSearchInput]="true"
                                                       [noEntriesFoundLabel]="'No items found'">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let option of courierServicesFiltered | async" [value]="option.value">
                                {{ option.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                    <mat-form-field class="full">
                        <mat-label>Courier service default</mat-label>
                        <mat-select formControlName="courier_service_id" required>
                            <mat-option *ngFor="let id of newForm.value.courier_services" [value]="id">
                                {{ courierServicesNames[id] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                        <mat-label>Invoice remark</mat-label>
                        <textarea matInput formControlName="invoice_remark"></textarea>
                    </mat-form-field>
                </div>

                <common-form-fields [fields]="customFields" #newFormCustomFields
                                    (valueChange)="updateCustomFields(newForm,$event)"></common-form-fields>

                <div class="actions">
                    <button [disabled]="!newForm.valid" mat-raised-button
                            color="primary" type="submit">
                        Submit
                    </button>
                    <button mat-raised-button type="button"
                            (click)="newForm.reset();newFormCustomFields.reset(); resetAutocomplete = !resetAutocomplete">
                        Reset
                    </button>
                </div>
            </form>
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>


<div *ngIf="logsTableSettings" style="padding-top: 30px">
    <h3 class="mat-h3">Change log</h3>
    <common-table2 #logTable [settings]="logsTableSettings" [search]="false"></common-table2>
</div>



