<h1 class="container-heading">Mark courier transaction as delivered</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Signed by (POD)</mat-label>
                    <input matInput formControlName="pod" required>
                </mat-form-field>

                <div class="full">
                    <common-form-date
                            label="Delivery date"
                            [value]="formGroup.value.delivered_at"
                            [required]="true"
                            (valueChangeMoment)="formGroup.get('delivered_at').setValue($event)"
                    ></common-form-date>
                </div>
            </div>
            <div class="actions">
                <button [disabled]="!formGroup.valid" mat-raised-button color="primary" type="submit" class="main">
                    Submit
                </button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
