<common-confirm></common-confirm>
<mat-card>

    <h1 class="color-accent">Select serial numbers to update</h1>

    <form [formGroup]="transferForm">

        <div class="group flex">

            <mat-form-field class="full">
                <mat-label>Partner</mat-label>
                <mat-select [formControl]="partnerSelect">
                    <mat-option *ngFor="let partner of partners" [value]="partner">
                        {{ partner.display_name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full" *ngIf="transferForm.value.partner_id">
                <mat-chip-grid #chipGrid>
                    <mat-chip-row matChipRemove *ngFor="let serial of serials"
                                  [removable]="true" (removed)="removeSerial(serial)">
                        {{ serial }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                </mat-chip-grid>

                <input placeholder="Serial" required

                       [matChipInputFor]="chipGrid"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="true"
                       (matChipInputTokenEnd)="addSerials($event)">
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Warehouse location transfer to</mat-label>
                <input #trigger="matAutocompleteTrigger" type="text" matInput [formControl]="locationSearchInput"
                       [matAutocomplete]="auto" required>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                  (optionSelected)="onOptionSelected($event)">
                    <mat-option disabled *ngIf="!locations.length">
                        Start typing to search locations
                    </mat-option>

                    <mat-option *ngFor="let option of locations_filtered | async" [value]="option">
                        <b>{{ option.location }}</b> ({{ option.warehouse_location_type?.name }})
                        - {{ option.inventory ? option.inventory.inventory_conversion?.gb : 'empty' }}
                    </mat-option>

                    <mat-option disabled *ngIf="locations.length">
                        Results are limited by 100 rows max
                    </mat-option>

                    <mat-option (click)="addLocation(locationSearchInput.value)">
                        <mat-icon>add</mat-icon>
                        Add location
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="actions">
            <button type="button" mat-raised-button color="accent" [disabled]="!transferForm.valid" (click)="submit()">
                Update
            </button>
        </div>
    </form>

</mat-card>
