<common-confirm></common-confirm>

<h1 class="container-heading">Auto-followup</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()" autocomplete="off" novalidate>

            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Title</mat-label>
                    <input matInput formControlName="title" required>
                </mat-form-field>

                <div class="full">
                    <h3>Create Followup when this happens:</h3>
                </div>

                <mat-form-field class="half" *ngIf="serviceLevels">
                    <mat-label>Service level</mat-label>
                    <mat-select formControlName="service_levels" [required]="true" [multiple]="true">

                        <mat-optgroup *ngFor="let group of serviceLevels | keys" [label]="group">
                            <mat-option *ngFor="let level of serviceLevels[group]" [value]="level.id">
                                {{ level.name }}
                                <small *ngIf="level.description">({{ level.description }})</small>
                            </mat-option>
                        </mat-optgroup>

                    </mat-select>
                </mat-form-field>

                <div class="half">
                    <common-form-select [options]="events"
                                        [required]="true"
                                        label="Event"
                                        [value]="formGroup.value.event"
                                        (valueChange)="formGroup.get('event').setValue($event)">
                    </common-form-select>
                </div>

                <div class="half">
                    <common-form-hub-select
                            [multiple]="true"
                            label="Hubs"
                            [value]="formGroup.value.hubs"
                            (onClose)="formGroup.get('hubs').setValue($event)">
                    </common-form-hub-select>
                </div>

                <mat-form-field class="half">
                    <mat-label>Customers</mat-label>
                    <mat-select formControlName="customers" [multiple]="true">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="customersSearch"
                                                   [placeholderLabel]="'Search'"
                                                   [showToggleAllCheckbox]="selectAll"
                                                   (toggleAll)="allCustomersSelect()"
                                                   [noEntriesFoundLabel]="'nothing found'"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let customer of customersFiltered" [value]="customer.id">
                            {{ customer.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half"
                                *ngIf="formGroup.controls['event'].value !== 'App\\Events\\order\\OrderCreated'">
                    <mat-label>Couriers service</mat-label>
                    <mat-select formControlName="courier_services" [multiple]="true">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="couriersServiceLevelSearch"
                                                   [placeholderLabel]="'Search'"
                                                   [showToggleAllCheckbox]="selectAll"
                                                   (toggleAll)="allCountriesSelect()"
                                                   [noEntriesFoundLabel]="'nothing found'"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let courier of couriersServiceLevelFiltered" [value]="courier.value">
                            {{ courier.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Excluded Countries</mat-label>
                    <mat-select formControlName="countries" [multiple]="true">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="countriesSearch"
                                                   [placeholderLabel]="'Search'"
                                                   [showToggleAllCheckbox]="selectAll"
                                                   (toggleAll)="allCountriesSelect()"
                                                   [noEntriesFoundLabel]="'nothing found'"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let country of countriesFiltered" [value]="country.value">
                            {{ country.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half"
                                *ngIf="formGroup.controls['event'].value === 'App\\Events\\order\\OrderConfirmed'">
                    <mat-label>Part masters</mat-label>
                    <mat-select formControlName="partmasters" [multiple]="true">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="partmastersSearch"
                                                   [placeholderLabel]="'Search'"
                                                   [showToggleAllCheckbox]="selectAll"
                                                   (toggleAll)="allPartmastersSelect()"
                                                   [noEntriesFoundLabel]="'nothing found'"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let partmaster of partmastersFiltered" [value]="partmaster.value">
                            {{ partmaster.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container formGroupName="params" *ngFor="let parameter of currentEventParams?.params">

                    <mat-form-field class="half" *ngIf="parameter.input_type === 'number'">
                        <mat-label>{{ parameter.label }}</mat-label>
                        <input matInput type="number" [formControlName]="parameter.name" required>
                    </mat-form-field>

                    <mat-checkbox class="full" *ngIf="parameter.input_type === 'checkbox'"
                                [formControlName]="parameter.name"
                                (change)="formGroup.get('params').get(parameter.name).setValue($event?.checked)">
                        {{ parameter.label }}
                    </mat-checkbox>

                    <common-form-select class="half" *ngIf="parameter.input_type === 'dropdown'"
                                        [label]="parameter.label"
                                        [options]="parameter.options"
                                        [value]="formGroup.get('params').get(parameter.name).value"
                                        [required]="true"
                                        (valueChange)="formGroup.get('params').get(parameter.name).setValue($event)">
                    </common-form-select>

                </ng-container>

                <div class="full">
                    <h3>Choose type and assign to:</h3>
                </div>

                <div class="half" *ngIf="teams.length">
                    <common-form-select
                            label="Assigned team"
                            [options]="teams"
                            [value]="formGroup.value.team_id"
                            [search]="true"
                            [required]="true"
                            (valueChange)="formGroup.get('team_id').setValue($event)"></common-form-select>
                </div>

                <div class="half" *ngIf="kinds.length">
                    <common-form-select
                            label="Kind"
                            [options]="kinds"
                            [value]="formGroup.value.request_kind_id"
                            [search]="true"
                            [required]="true"
                            (valueChange)="formGroup.get('request_kind_id').setValue($event)"></common-form-select>
                </div>

                <mat-form-field class="half" *ngIf="remarkTypes.length">
                    <mat-label>Who can see</mat-label>
                    <mat-select formControlName="order_remark_type_id" required>
                        <mat-option *ngFor="let option of remarkTypes" [value]="option.id">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                    <span matPrefix><mat-icon class="vertical-middle">remove_red_eyes</mat-icon>&nbsp;</span>
                </mat-form-field>

                <div class="half">
                    <mat-checkbox formControlName="send_email">Send email</mat-checkbox>
                </div>
            </div>

            <h3>Tasks</h3>
            <div *ngIf="formGroup.get('tasks').controls.length">
                <ng-container formArrayName="tasks" *ngFor="let task of formGroup.get('tasks').controls; let i = index">
                    <mat-card [formGroupName]="i" class="margin-bottom-10">
                        <div class="group flex">
                            <mat-form-field class="full">
                                <mat-label>Task</mat-label>
                                <input matInput type="text" formControlName="name" required>
                            </mat-form-field>

                            <mat-form-field class="full">
                                <mat-label>Comment</mat-label>
                                <input matInput type="text" formControlName="comment">
                            </mat-form-field>

                            <mat-form-field class="third2">
                                <mat-label>Deadline type</mat-label>
                                <mat-select formControlName="deadline_type" required>
                                    <mat-option value="time">
                                        Time
                                    </mat-option>
                                    <mat-option value="wh">
                                        Warehouse cutoff
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="third">
                                <mat-label>Deadline in minutes</mat-label>
                                <input type="number" matInput formControlName="deadline"
                                       noscroll
                                >
                            </mat-form-field>

                            <div class="full">
                                <mat-checkbox formControlName="deadline_extend">
                                    Extend deadline time for holidays and weekends
                                </mat-checkbox>
                            </div>

                            <mat-form-field class="full">
                                <mat-label>Event for close</mat-label>
                                <mat-select formControlName="event_close">
                                    <mat-option [value]="null">None</mat-option>
                                    <mat-option *ngFor="let option of closeTaskEvents" [value]="option.value">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <ng-container formGroupName="params" *ngIf="getTaskParamsForEvent(task) as params">
                                <div *ngFor="let parameter of params" class="full">
                                    <mat-form-field class="full" *ngIf="parameter.input_type === 'number'">
                                        <mat-label>{{ parameter.label }}</mat-label>
                                        <input matInput type="number" [formControlName]="parameter.name"
                                               [required]="parameter.required">
                                    </mat-form-field>

                                    <mat-checkbox class="full" *ngIf="parameter.input_type === 'checkbox'"
                                                  [formControlName]="parameter.name"
                                                  [required]="parameter.required">
                                        {{ parameter.label }}
                                    </mat-checkbox>

                                    <common-form-select class="full" *ngIf="parameter.input_type === 'dropdown'"
                                                        [label]="parameter.label"
                                                        [options]="parameter.options"
                                                        [value]="task.get('params').get(parameter.name).value"
                                                        [required]="parameter.required"
                                                        (valueChange)="task.get('params').get(parameter.name).setValue($event)">
                                    </common-form-select>
                                </div>
                            </ng-container>

                            <div class="third2">
                                <mat-checkbox formControlName="send_email" class="third2 margin-top-15">
                                    Send email on due date arrival
                                </mat-checkbox>

                                <mat-form-field class="pull-right third">
                                    <mat-label>Deadline in minutes</mat-label>
                                    <mat-select formControlName="send_email_deadline">
                                        <mat-option *ngFor="let option of taskDeadlineOptions" [value]="option">
                                            {{ option }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>


                            <div>
                                <mat-icon matSuffix color="warn" (click)="removeTask(i)" class="pointer">
                                    close
                                </mat-icon>
                            </div>
                        </div>
                    </mat-card>
                </ng-container>
            </div>
            <div class="group">
                <button type="button" mat-raised-button color="accent" (click)="addTask()">
                    Add task
                </button>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    Submit
                </button>
                <ng-container *ngIf="state.params.id">
                    <button mat-raised-button *userAllowed="['delete_auto_followups']"
                            type="button" color="warn" (click)="delete()">
                        Delete
                    </button>
                </ng-container>
                <button mat-button type="button" (click)="showList()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
