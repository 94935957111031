import {NgModule, Type} from "@angular/core";
import {CommonModule} from "../common/common.module";
import {
    SubWarehouseListComponent,
    WarehouseContactsListComponent,
    WarehouseFormComponent,
    WarehouseListComponent,
    WarehouseSettingsComponent,
    WarehouseViewComponent
} from "./components/threepl/warehouse";
import {OutletComponent} from "./components/outlet.component";
import {ModalService} from "./services/modal.service";
import {ParcelFormComponent, ParcelItemsListModalComponent} from "./components/threepl/warehouse/parcel";
import {
    WarehouseOrderAllListComponent,
    WarehouseOrderClosedListComponent,
    WarehouseOrderExceptionListComponent,
    WarehouseOrderListComponent,
    WarehouseOrderOnHoldListComponent,
    WarehouseOrderOpenListComponent,
    WarehouseOrderPackedListComponent,
    WarehouseOrderPendingPickupListComponent,
    WarehouseOrderPendingUnilogListComponent,
    WarehouseOrderReplenishmentClosedListComponent,
    WarehouseOrderReplenishmentListComponent,
    WarehouseOrderReplenishmentOpenListComponent,
    WarehouseOrderReturnClosedListComponent,
    WarehouseOrderReturnListComponent,
    WarehouseOrderReturnOpenListComponent,
    WarehouseOrderRmaClosedListComponent,
    WarehouseOrderRmaListComponent,
    WarehouseOrderRmaOpenListComponent,
    WarehouseOrderSalesClosedListComponent,
    WarehouseOrderSalesListComponent,
    WarehouseOrderSalesOpenListComponent,
    WarehouseOrderWizardAssemblyTransactionsComponent,
    WarehouseOrderWizardComponent,
    WarehouseOrderWizardCourierTransactionsComponent,
    WarehouseOrderWizardCourierTransactionsToShipmentComponent,
    WarehouseOrderWizardSelectParcelComponent,
    WarehouseOrderWizardSelectShipmentComponent,
    WarehouseOrderWizardShipmentsTypeComponent,
    WarehouseOrderWizardWarehouseTransactionsComponent,
    WarehouseTransactionFormComponent,
    WarehouseTransactionSerialComponent
} from "./components/threepl/warehouse/order";
import {
    AvailableLocationsModalComponent,
    BoxItemsListComponent,
    FindLocationByItemModalComponent,
    InventoryChangeLocationModalComponent,
    InventoryCountItemFormComponent,
    PalletItemsListComponent,
    WarehouseAttachmentsListComponent,
    WarehouseBufferScanFormComponent,
    WarehouseInventoryCountFormComponent,
    WarehouseInventoryCountListComponent,
    WarehouseProceduresInventoryCountScanComponent,
    WarehouseProceduresInventoryCountScanResultsComponent,
    WarehouseProceduresInventoryCountWizardComponent,
    WarehouseProceduresWizardCheckPackagesComponent,
    WarehouseProceduresWizardFreeInboundComponent,
    WarehouseProceduresWizardFreeInboundResultsComponent,
    WarehouseProceduresWizardFreeInboundScanComponent,
    WarehouseProceduresWizardInboundComponent,
    WarehouseProceduresWizardInboundScanBoxesComponent,
    WarehouseProceduresWizardInboundScanComponent,
    WarehouseProceduresWizardInboundScanDefaultComponent,
    WarehouseProceduresWizardLockdownComponent,
    WarehouseProceduresWizardOrderSearchComponent,
    WarehouseProceduresWizardOutboundComponent,
    WarehouseProceduresWizardOutboundScanBoxesComponent,
    WarehouseProceduresWizardOutboundScanComponent,
    WarehouseProceduresWizardOutboundScanDefaultComponent,
    WarehouseProceduresWizardOutboundSelectShipmentComponent,
    WarehouseProceduresWizardReleaseDocumentsComponent,
    WarehouseProceduresWizardScanBoxResultsComponent,
    WarehouseProceduresWizardScanDefaultResultsComponent,
    WarehouseProceduresWizardScanTypeComponent,
    WarehouseProceduresWizardSelectLockerCellModalComponent,
    WarehouseProceduresWizardSelectOrderItemModalComponent,
    WarehouseProceduresWizardShuffleBoxesComponent,
    WarehouseProceduresWizardShuffleItemsComponent,
    WarehouseProceduresWizardShufflePackagesComponent,
    WarehouseProceduresWizardTransferComponent,
    WarehouseProceduresWizardTransferHubToHubComponent,
    WarehouseProceduresWizardTransferLocationToLocationComponent,
    WarehouseProceduresWizardTransferUpdateBoxComponent,
    WarehouseProceduresWizardTransferUpdatePalletComponent,
    WarehouseProceduresWizardTransferUpdateSerialHubComponent,
    WarehouseProceduresWizardTransferUpdateSerialLocationComponent,
} from "./components/threepl/warehouse/procedures";
import {
    PackageTypeAllComponent,
    PackageTypeListComponent,
    PackageTypeViewComponent
} from "./components/threepl/warehouse/package-type";
import {
    CourierTransactionDeliveredFormComponent,
    CourierTransactionFormComponent
} from "./components/threepl/warehouse/courier-transaction";


import {
    LocationTypeAllComponent,
    LocationTypeListComponent,
    LocationTypeViewComponent
} from "./components/threepl/warehouse/location-type";
import {
    WarehouseLocationFormComponent,
    WarehouseLocationsListComponent,
    WarehouseLocationViewComponent
} from "./components/threepl/warehouse/locations";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {
    BoxFormComponent,
    BoxSticker2Component,
    BoxStickerComponent,
    WarehouseBoxesListComponent
} from "./components/threepl/warehouse/boxes";
import {
    PalletStickerComponent,
    PalletSticker2Component,
    WarehousePalletsListComponent,
} from "./components/threepl/warehouse/pallets";
import {MessageFormComponent} from "../common/components/message-form/form.component";
import {
    PackageFormComponent,
    PackageStickerBoxComponent,
    PackageStickerComponent,
    PackageStickerDefaultComponent
} from "./components/threepl/warehouse/package";

import {StockSummarizedByLocationComponent}
    from "./components/threepl/warehouse/stock/summarized-by-location.component";
import {AdminSectionModule} from "./components/admin/admin-section.module";
import {CommonSectionModule} from "./components/common/common-section.module";
import {PartnerSectionModule} from "./components/partner/partner-section.module";

export const EntryComponents: Type<any>[] = [
    ParcelItemsListModalComponent,
    SubWarehouseListComponent,
    WarehouseOrderListComponent,
    WarehouseContactsListComponent,
    WarehouseOrderWizardAssemblyTransactionsComponent,
    WarehouseProceduresWizardOrderSearchComponent,
    WarehouseTransactionFormComponent,
    WarehouseTransactionSerialComponent,
    WarehouseOrderWizardSelectShipmentComponent,
    WarehouseOrderWizardShipmentsTypeComponent,
    WarehouseOrderWizardWarehouseTransactionsComponent,
    WarehouseOrderWizardCourierTransactionsComponent,
    CourierTransactionFormComponent,
    ParcelFormComponent,
    WarehouseOrderWizardCourierTransactionsToShipmentComponent,
    WarehouseOrderWizardSelectParcelComponent,
    WarehouseProceduresWizardOutboundScanComponent,
    WarehouseProceduresWizardInboundScanComponent,
    WarehouseProceduresWizardInboundScanBoxesComponent,
    WarehouseProceduresWizardInboundScanDefaultComponent,
    WarehouseProceduresWizardTransferUpdateBoxComponent,
    WarehouseProceduresWizardScanTypeComponent,
    BoxItemsListComponent,
    PalletItemsListComponent,
    WarehouseProceduresWizardOutboundScanBoxesComponent,
    WarehouseProceduresWizardOutboundSelectShipmentComponent,
    PackageFormComponent,
    WarehouseProceduresWizardCheckPackagesComponent,
    PackageTypeListComponent,
    PackageTypeViewComponent,
    LocationTypeListComponent,
    LocationTypeViewComponent,
    WarehouseProceduresWizardReleaseDocumentsComponent,
    BoxStickerComponent,
    PackageStickerComponent,
    PackageStickerDefaultComponent,
    PackageStickerBoxComponent,
    WarehouseProceduresWizardTransferUpdateSerialHubComponent,
    WarehouseProceduresWizardTransferUpdateSerialLocationComponent,
    WarehouseInventoryCountFormComponent,
    WarehouseLocationFormComponent,
    MessageFormComponent,
    FindLocationByItemModalComponent,
    AvailableLocationsModalComponent,
    WarehouseProceduresWizardOutboundScanDefaultComponent,
    WarehouseProceduresWizardSelectOrderItemModalComponent,
    WarehouseProceduresWizardScanDefaultResultsComponent,
    WarehouseProceduresWizardScanBoxResultsComponent,
    InventoryChangeLocationModalComponent,
    WarehouseProceduresWizardTransferLocationToLocationComponent,
    WarehouseProceduresWizardShufflePackagesComponent,
    WarehouseProceduresWizardLockdownComponent,
    WarehouseProceduresInventoryCountScanComponent,
    InventoryCountItemFormComponent,
    WarehouseProceduresInventoryCountScanResultsComponent,
    WarehouseProceduresWizardTransferHubToHubComponent,
    CourierTransactionDeliveredFormComponent,
    BoxFormComponent,
    BoxSticker2Component,
    WarehouseAttachmentsListComponent,
    WarehouseProceduresWizardTransferUpdatePalletComponent,
    WarehouseBufferScanFormComponent,
    WarehouseProceduresWizardSelectLockerCellModalComponent,
    WarehouseProceduresWizardShuffleItemsComponent,
    WarehouseProceduresWizardShuffleBoxesComponent,
    PalletStickerComponent,
    PalletSticker2Component,
    WarehouseProceduresWizardFreeInboundScanComponent,
    WarehouseProceduresWizardFreeInboundResultsComponent
];

export const routingComponents: Type<any>[] = [
    WarehouseFormComponent,
    WarehouseListComponent,
    WarehouseViewComponent,
    WarehouseOrderAllListComponent,
    WarehouseOrderOpenListComponent,
    WarehouseOrderExceptionListComponent,
    WarehouseOrderClosedListComponent,
    WarehouseOrderSalesListComponent,
    WarehouseOrderSalesOpenListComponent,
    WarehouseOrderSalesClosedListComponent,
    WarehouseOrderReplenishmentListComponent,
    WarehouseOrderReplenishmentOpenListComponent,
    WarehouseOrderReplenishmentClosedListComponent,
    WarehouseOrderReturnListComponent,
    WarehouseOrderReturnOpenListComponent,
    WarehouseOrderReturnClosedListComponent,
    WarehouseOrderRmaListComponent,
    WarehouseOrderRmaOpenListComponent,
    WarehouseOrderRmaClosedListComponent,
    WarehouseOrderWizardComponent,
    WarehouseProceduresWizardInboundComponent,
    WarehouseProceduresWizardTransferComponent,
    WarehouseSettingsComponent,
    WarehouseProceduresWizardOutboundComponent,
    PackageTypeAllComponent,
    LocationTypeAllComponent,
    WarehouseLocationsListComponent,
    WarehouseLocationViewComponent,
    WarehouseBoxesListComponent,
    WarehousePalletsListComponent,
    WarehouseInventoryCountListComponent,
    WarehouseProceduresInventoryCountWizardComponent,
    WarehouseOrderPackedListComponent,
    WarehouseOrderOnHoldListComponent,
    StockSummarizedByLocationComponent,
    WarehouseOrderPendingUnilogListComponent,
    WarehouseOrderPendingPickupListComponent,
    WarehouseProceduresWizardFreeInboundComponent
];

@NgModule({
    imports: [
        CommonModule,
        AdminSectionModule,
        PartnerSectionModule,
        CommonSectionModule
    ],
    declarations: [
        ...EntryComponents,
        ...routingComponents,
        OutletComponent,
    ],
    providers: [
        ModalService,
        {provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
    exports: [
        OutletComponent
    ]
})
export class SectionModule {
}

export {OutletComponent};
