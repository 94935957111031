<common-confirm></common-confirm>

<h1 class="container-heading">{{ state.action | ucfirst }} currency</h1>

<mat-card>
    <mat-card-content>

        <form class="form" [formGroup]="formGroup" *ngIf="formGroup" (submit)="handleFormSubmit()">

            <div class="group flex">

                <div class="full">
                    <common-form-select
                            label="Currency code"
                            [options]="world_currencies"
                            [value]="formGroup.value.code"
                            [search]="true"
                            [required]="true"
                            (valueChange)="formGroup.get('code').setValue($event)"
                    ></common-form-select>
                </div>

                <div class="full" formArrayName="rates">
                    <div>
                        <b>Exchange rates:</b>
                    </div>
                    <div *ngFor="let group of rates.controls; let i=index">
                        <div [formGroupName]="i" class="group flex">
                            <div class="quarter">
                                {{ group.value.code }}
                            </div>
                            <mat-form-field class="quarter">
                                <input matInput formControlName="rate" type="number" step="0.001" min="0.001">
                            </mat-form-field>
                            <div class="half">
                                <mat-checkbox formControlName="autoupdate">
                                    Update automatically
                                </mat-checkbox>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    Save
                </button>

                <button mat-raised-button type="button" color="warn" (click)="delete()">
                    Delete
                </button>

                <button mat-button type="button" (click)="showList()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>