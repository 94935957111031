import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Modal} from "../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-courier-transaction-form-delivered",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CourierTransactionDeliveredFormComponent {


    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        pod: new FormControl(null, [Validators.required]),
        delivered_at: new FormControl((new Date()).toDateString(), [Validators.required])
    });

    public modal: Modal.IModal;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private spinnerService: SpinnerService
    ) {
    }


    /**
     * Add/edit courier transaction
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {

        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["courier", "transactions", "" + this.modal.params.id], {
                pod: this.formGroup.value.pod,
                status: "delivered",
                delivered_at: this.formGroup.value.delivered_at.format("YYYY-MM-DD HH:mm:ss")
            });
        this.spinnerService.hide();
        if (data) {
            this.modal.response.emit({
                value: true,
                name: "result"
            });
        }
    }
}
