import {NgModule, Type} from "@angular/core";
import {CommonSectionModule} from "../common/common-section.module";
import {
    AddressFormComponent,
    AddressListComponent,
    AddressListModalComponent,
    LinkPartMastersToAddressComponent,
    LinkServiceLevelsToAddressComponent
} from "./components/address";
import {
    AdjustTransactionsComponent,
    AdjustTransactionsViewComponent,
    AdjustTransactionsViewNoOrderComponent,
    NoOrderTransactionsComponent,
    UnusedTransactionsComponent
} from "./components/adjust-transactions";
import {
    AllocationsListComponent,
    InboundDetailedAllocationsListComponent,
    InboundSummarizedAllocationsListComponent,
    InTransitListComponent,
    OutboundAllocationsListComponent
} from "./components/allocations";
import {InTransitModalComponent} from "./components/allocations/list/in-transit-modal.component";
import {AutoFollowupFormComponent, AutoFollowupsListComponent} from "./components/auto-followup";
import {BatchFormComponent, BatchListComponent} from "./components/batch";
import {BranchesComponent, BranchFormComponent} from "./components/branch";
import {ContactFormComponent, ContactListComponent} from "./components/contact";
import {
    PartMasterAnalogFormComponent,
    PartMasterAnalogsListComponent,
    PartMasterConversionsFormComponent,
    PartMasterConversionsListComponent,
    PartMasterKitFormComponent,
    PartMasterKitsListComponent,
    PartnerPartMasterListComponent
} from "./components/part-masters";
import {
    CountriesInstructionsListComponent,
    CountryInstructionsFormComponent
} from "./components/countries-instructions";
import {PartnerCoveragesComponent} from "./components/coverages/coverages.component";
import {CustomerFormComponent, CustomerListComponent} from "./components/customer";
import {CustomerAccountFormComponent, CustomerAccountListComponent} from "./components/customer-account";
import {HubsManageComponent, HubsMapModalComponent, LinkHubsToCourierServicesModalComponent} from "./components/hubs";
import {
    HttpLogsComponent,
    HttpLogsViewComponent,
    IntegrationsFormComponent,
    IntegrationsFunctionsFormComponent,
    IntegrationsListComponent,
    IntegrationsViewComponent
} from "./components/integrations";
import {InventoryConditionFormComponent, InventoryConditionListComponent} from "./components/inventory-condition-bias";
import {MilestoneFormComponent, MilestoneReasonFormComponent, MilestonesListComponent} from "./components/milestones";
import {
    EditShipmentEtaComponent,
    OrderActivitiesComponent,
    OrderAddressComponent,
    OrderAttachmentsListComponent,
    OrderCancelModalComponent,
    OrderEditAddressModalComponent,
    OrderItemComponent,
    OrderItemsComponent,
    OrderItemsInboundComponent,
    OrderItemsInfoComponent,
    OrderItemsOutboundComponent,
    OrderItemStickerComponent,
    OrderJobsComponent,
    OrderLifecycleComponent,
    OrderListAllByItemComponent,
    OrderListByItemComponent,
    OrderParcelsComponent,
    OrderRemarksComponent,
    OrderResellerSelectComponent,
    OrderSetOnHoldModalComponent,
    OrderShipmentsComponent,
    OrderShipmentWizardComponent,
    OrderShipmentWizardStep1Component,
    OrderShipmentWizardStep2Component,
    OrderSLAComponent,
    OrderTrackContentComponent,
    OrderTrackProgressComponent,
    OrderTrackProgressMapComponent,
    OrderViewComponent,
    OrderWarehouseOrderComponent,
    OrderWizardComponent,
    OrderWizardContractComponent,
    OrderWizardCourierServiceComponent,
    OrderWizardDetailsComponent,
    OrderWizardDetailsMavenirComponent,
    OrderWizardInstructionsComponent,
    OrderWizardItemReplenishmentComponent,
    OrderWizardItemRmaComponent,
    OrderWizardItemSalesComponent,
    OrderWizardItemsReplenishmentComponent,
    OrderWizardItemsRmaComponent,
    OrderWizardItemsSalesComponent,
    OrderWizardItemsTransferComponent,
    OrderWizardItemTransferComponent,
    OrderWizardOriginComponent,
    OrderWizardServiceLevelComponent,
    OrderWizardServiceLevelMavenirComponent,
    OrderWizardShipmentDetailsComponent,
    OrderWizardTransferDetailsComponent,
    ShipmentFormComponent
} from "./components/order";

import {AllByItemModalComponent} from "./components/order/list-by-item/all-by-item-modal.component";
import {PickupRequestFormComponent} from "./components/pickup-requests/form/form.component";
import {TrackNumberFormComponent} from "./components/tracking/form/form.component";
import {
    PartnerProceduresWizardAddSerialComponent,
    PartnerProceduresWizardBoxRefChangeComponent,
    PartnerProceduresWizardLinkSerialToBoxComponent,
    PartnerProceduresWizardPalletRefChangeComponent,
    PartnerProceduresWizardRemoveSerialComponent,
    PartnerProceduresWizardSerialToQtyComponent,
    PartnerProceduresWizardTypeComponent,
    PartnerProceduresWizardUnlinkSerialFromBoxComponent,
    PartnerProceduresWizardUpdateBatchComponent,
    PartnerProceduresWizardUpdateBoxHubComponent,
    PartnerProceduresWizardUpdateConfigurationForSerialComponent,
    PartnerProceduresWizardUpdateHubComponent,
    PartnerProceduresWizardUpdateItemComponent,
    PartnerProceduresWizardUpdateItemForSerialComponent,
    PartnerProceduresWizardUpdateQuantityComponent,
    PartnerProceduresWizardUpdateSerialComponent
} from "./components/procedures/wizard";
import {PartnerProceduresWizardComponent} from "./components/procedures/wizard/wizard.component";
import {ResellerFormComponent, ResellerListComponent} from "./components/reseller";
import {PartnerServiceLevelsComponent} from "./components/service-levels/service-levels.component";
import {PartnerSettingsComponent} from "./components/settings/settings.component";
import {CommonModule} from "../../../common/common.module";
import {PartnerPartMasterService} from "./services/part-master.service";
import {PartnerOrderService} from "./services/order.service";
import {OrderMapModalComponent} from "./components/order/view/map-modal/map-modal.component";
import {StockMapComponent, StockSummarizedAnnaPurnaComponent} from "./components/stock";
import {ShippyProPickupFormComponent} from "./components/pickup-requests/shippy-pro-form/form.component";
import {HubGroupsListComponent} from "./components/hub-gropus";
import {
    PackageBoxesAddItemFormComponent,
    PackagePalletEditComponent,
    PackagesBoxesEditComponent,
    PackagesBoxesListComponent,
    PackagesPalletsListComponent,
    PartnerBoxItemsListModalComponent,
    PartnerBoxWarehouseTransactionsModalComponent
} from "./components/packages";
import {CurrencyFormComponent, CurrencyListComponent} from "./components/currency";

export const partnerRoutingComponents: Type<any>[] = [
    AdjustTransactionsComponent,
    NoOrderTransactionsComponent,
    UnusedTransactionsComponent,
    AdjustTransactionsViewComponent,
    AdjustTransactionsViewNoOrderComponent,
    InboundDetailedAllocationsListComponent,
    InboundSummarizedAllocationsListComponent,
    OutboundAllocationsListComponent,
    AutoFollowupFormComponent,
    AutoFollowupsListComponent,
    BatchListComponent,
    BranchesComponent,
    BranchFormComponent,
    PartMasterAnalogsListComponent,
    PartMasterKitsListComponent,
    PartMasterConversionsListComponent,
    CountryInstructionsFormComponent,
    CountriesInstructionsListComponent,
    PartnerCoveragesComponent,
    CustomerListComponent,
    HubsManageComponent,
    IntegrationsFormComponent,
    HttpLogsComponent,
    IntegrationsListComponent,
    IntegrationsViewComponent,
    InventoryConditionListComponent,
    MilestonesListComponent,
    OrderListAllByItemComponent,
    OrderShipmentWizardComponent,
    OrderViewComponent,
    OrderWizardComponent,
    TrackNumberFormComponent,
    PartnerProceduresWizardComponent,
    ResellerListComponent,
    PartnerServiceLevelsComponent,
    PartnerSettingsComponent,
    PartnerPartMasterListComponent,
    InTransitListComponent,
    StockSummarizedAnnaPurnaComponent,
    StockMapComponent,
    StockSummarizedAnnaPurnaComponent,
    PackagesBoxesListComponent,
    PackagesPalletsListComponent,
    PackagesBoxesEditComponent,
    HubGroupsListComponent,
    AddressListComponent,
    CurrencyListComponent,
    CurrencyFormComponent
];


@NgModule({
    imports: [
        CommonModule,
        CommonSectionModule
    ],

    declarations: [
        ...partnerRoutingComponents,
        AddressListModalComponent,
        AddressListComponent,
        LinkServiceLevelsToAddressComponent,
        LinkPartMastersToAddressComponent,
        AddressFormComponent,

        InTransitModalComponent,
        AllocationsListComponent,
        BatchFormComponent,
        ContactFormComponent,
        ContactListComponent,
        PartMasterAnalogFormComponent,
        PartMasterKitFormComponent,
        PartMasterConversionsFormComponent,
        CustomerFormComponent,
        CustomerAccountFormComponent,
        CustomerAccountListComponent,
        LinkHubsToCourierServicesModalComponent,
        HubsMapModalComponent,
        HttpLogsViewComponent,
        IntegrationsFunctionsFormComponent,
        InventoryConditionFormComponent,
        MilestoneFormComponent,
        MilestoneReasonFormComponent,
        OrderActivitiesComponent,
        OrderAttachmentsListComponent,
        OrderItemStickerComponent,
        AllByItemModalComponent,
        OrderListByItemComponent,
        OrderRemarksComponent,
        OrderResellerSelectComponent,
        OrderShipmentWizardStep1Component,
        OrderShipmentWizardStep2Component,
        OrderTrackContentComponent,
        OrderTrackProgressComponent,
        OrderTrackProgressMapComponent,
        OrderAddressComponent,
        OrderCancelModalComponent,
        OrderEditAddressModalComponent,
        OrderSetOnHoldModalComponent,
        OrderItemComponent,
        OrderItemsInboundComponent,
        OrderItemsInfoComponent,
        OrderItemsOutboundComponent,
        OrderItemsComponent,
        OrderParcelsComponent,
        OrderShipmentsComponent,
        OrderSLAComponent,
        OrderWarehouseOrderComponent,
        OrderWizardContractComponent,
        OrderWizardCourierServiceComponent,
        OrderWizardShipmentDetailsComponent,
        OrderWizardDetailsComponent,
        OrderWizardDetailsMavenirComponent,
        OrderWizardOriginComponent,
        OrderWizardInstructionsComponent,
        OrderWizardItemReplenishmentComponent,
        OrderWizardItemRmaComponent,
        OrderWizardItemSalesComponent,
        OrderWizardItemTransferComponent,
        OrderWizardItemsReplenishmentComponent,
        OrderWizardItemsRmaComponent,
        OrderWizardItemsSalesComponent,
        OrderWizardItemsTransferComponent,
        OrderWizardServiceLevelComponent,
        OrderWizardServiceLevelMavenirComponent,
        OrderWizardTransferDetailsComponent,
        OrderJobsComponent,
        OrderLifecycleComponent,
        PickupRequestFormComponent,
        PartnerProceduresWizardAddSerialComponent,
        PartnerProceduresWizardRemoveSerialComponent,
        PartnerProceduresWizardTypeComponent,
        PartnerProceduresWizardSerialToQtyComponent,
        PartnerProceduresWizardUpdateBatchComponent,
        PartnerProceduresWizardUpdateBoxHubComponent,
        PartnerProceduresWizardUpdateHubComponent,
        PartnerProceduresWizardUpdateItemComponent,
        PartnerProceduresWizardUpdateItemForSerialComponent,
        PartnerProceduresWizardUpdateQuantityComponent,
        PartnerProceduresWizardUpdateSerialComponent,
        PartnerProceduresWizardUpdateConfigurationForSerialComponent,
        PartnerProceduresWizardUnlinkSerialFromBoxComponent,
        PartnerProceduresWizardLinkSerialToBoxComponent,
        PartnerProceduresWizardPalletRefChangeComponent,
        PartnerProceduresWizardBoxRefChangeComponent,
        ResellerFormComponent,
        EditShipmentEtaComponent,
        OrderMapModalComponent,
        ShippyProPickupFormComponent,
        ShipmentFormComponent,
        PackageBoxesAddItemFormComponent,
        PartnerBoxItemsListModalComponent,
        PartnerBoxWarehouseTransactionsModalComponent,
        PackagePalletEditComponent
    ],
    providers: [
        PartnerPartMasterService,
        PartnerOrderService
    ],
    exports: []
})
export class PartnerSectionModule {
}
