<common-confirm></common-confirm>
<mat-card>

    <h1 class="color-accent">Select serial numbers to update</h1>

    <form [formGroup]="transferForm">
        <div class="group flex">
            <mat-form-field class="full">
                <mat-chip-grid #chipGrid>
                    <mat-chip-row matChipRemove *ngFor="let serial of serials"
                                  [removable]="true" (removed)="removeSerial(serial)">
                        {{serial}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                </mat-chip-grid>
                <input placeholder="Serial" required
                       [matChipInputFor]="chipGrid"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="true"
                       (matChipInputTokenEnd)="addSerials($event)">
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Partner</mat-label>
                <mat-select [formControl]="partnerSelect">
                    <mat-option *ngFor="let partner of partners" [value]="partner">
                        {{partner.display_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full" *ngIf="subs.length">
                <mat-label>Sub-warehouse transfer to</mat-label>
                <mat-select formControlName="sub_inventory">
                    <mat-option *ngFor="let sub of subs" [value]="sub.sub_inventory">
                        {{sub.sub_inventory}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="actions">
            <button type="button" mat-raised-button color="accent" [disabled]="!transferForm.valid" (click)="submit()">
                Update
            </button>
        </div>
    </form>

</mat-card>
