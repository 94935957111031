export const CURRENCIES = [
    // {code: "AED", name: "UAE Dirham", number: 784},
    // {code: "AFN", name: "Afghani", number: 971},
    // {code: "ALL", name: "Lek", number: 8},
    // {code: "AMD", name: "Armenian Dram", number: 51},
    // {code: "ANG", name: "Netherlands Antillean Guilder", number: 532},
    // {code: "AOA", name: "Kwanza", number: 973},
    // {code: "ARS", name: "Argentine Peso", number: 32},
    {code: "AUD", name: "Australian Dollar", number: 36},
    // {code: "AWG", name: "Aruban Florin", number: 533},
    // {code: "AZN", name: "Azerbaijanian Manat", number: 944},
    // {code: "BAM", name: "Convertible Mark", number: 977},
    // {code: "BBD", name: "Barbados Dollar", number: 52},
    // {code: "BDT", name: "Taka", number: 50},
    {code: "BGN", name: "Bulgarian Lev", number: 975},
    // {code: "BHD", name: "Bahraini Dinar", number: 48},
    // {code: "BIF", name: "Burundi Franc", number: 108},
    // {code: "BMD", name: "Bermudian Dollar", number: 60},
    // {code: "BND", name: "Brunei Dollar", number: 96},
    // {code: "BOB", name: "Boliviano", number: 68},
    // {code: "BOV", name: "Mvdol", number: 984},
    {code: "BRL", name: "Brazilian Real", number: 986},
    // {code: "BSD", name: "Bahamian Dollar", number: 44},
    // {code: "BTN", name: "Ngultrum", number: 64},
    // {code: "BWP", name: "Pula", number: 72},
    // {code: "BYN", name: "Belarussian Ruble", number: 933},
    // {code: "BZD", name: "Belize Dollar", number: 84},
    {code: "CAD", name: "Canadian Dollar", number: 124},
    // {code: "CDF", name: "Congolese Franc", number: 976},
    // {code: "CHE", name: "WIR Euro", number: 947},
    {code: "CHF", name: "Swiss Franc", number: 756},
    // {code: "CHW", name: "WIR Franc", number: 948},
    // {code: "CLF", name: "Unidad de Fomento", number: 990},
    // {code: "CLP", name: "Chilean Peso", number: 152},
    {code: "CNY", name: "Yuan Renminbi", number: 156},
    // {code: "COP", name: "Colombian Peso", number: 170},
    // {code: "COU", name: "Unidad de Valor Real", number: 970},
    // {code: "CRC", name: "Costa Rican Colon", number: 188},
    // {code: "CUC", name: "Peso Convertible", number: 931},
    // {code: "CUP", name: "Cuban Peso", number: 192},
    // {code: "CVE", name: "Cabo Verde Escudo", number: 132},
    {code: "CZK", name: "Czech Koruna", number: 203},
    // {code: "DJF", name: "Djibouti Franc", number: 262},
    {code: "DKK", name: "Danish Krone", number: 208},
    // {code: "DOP", name: "Dominican Peso", number: 214},
    // {code: "DZD", name: "Algerian Dinar", number: 12},
    // {code: "EGP", name: "Egyptian Pound", number: 818},
    // {code: "ERN", name: "Nakfa", number: 232},
    // {code: "ETB", name: "Ethiopian Birr", number: 230},
    {code: "EUR", name: "Euro", number: 978},
    // {code: "FJD", name: "Fiji Dollar", number: 242},
    // {code: "FKP", name: "Falkland Islands Pound", number: 238},
    {code: "GBP", name: "Pound Sterling", number: 826},
    // {code: "GEL", name: "Lari", number: 981},
    // {code: "GHS", name: "Ghana Cedi", number: 936},
    // {code: "GIP", name: "Gibraltar Pound", number: 292},
    // {code: "GMD", name: "Dalasi", number: 270},
    // {code: "GNF", name: "Guinea Franc", number: 324},
    // {code: "GTQ", name: "Quetzal", number: 320},
    // {code: "GYD", name: "Guyana Dollar", number: 328},
    {code: "HKD", name: "Hong Kong Dollar", number: 344},
    // {code: "HNL", name: "Lempira", number: 340},
    // {code: "HTG", name: "Gourde", number: 332},
    {code: "HUF", name: "Forint", number: 348},
    {code: "IDR", name: "Rupiah", number: 360},
    {code: "ILS", name: "New Israeli Sheqel", number: 376},
    {code: "INR", name: "Indian Rupee", number: 356},
    // {code: "IQD", name: "Iraqi Dinar", number: 368},
    // {code: "IRR", name: "Iranian Rial", number: 364},
    {code: "ISK", name: "Iceland Krona", number: 352},
    // {code: "JMD", name: "Jamaican Dollar", number: 388},
    // {code: "JOD", name: "Jordanian Dinar", number: 400},
    {code: "JPY", name: "Yen", number: 392},
    // {code: "KES", name: "Kenyan Shilling", number: 404},
    // {code: "KGS", name: "Som", number: 417},
    // {code: "KHR", name: "Riel", number: 116},
    // {code: "KMF", name: "Comoro Franc", number: 174},
    // {code: "KPW", name: "North Korean Won", number: 408},
    {code: "KRW", name: "South Korean Won", number: 410},
    // {code: "KWD", name: "Kuwaiti Dinar", number: 414},
    // {code: "KYD", name: "Cayman Islands Dollar", number: 136},
    // {code: "KZT", name: "Tenge", number: 398},
    // {code: "LAK", name: "Kip", number: 418},
    // {code: "LBP", name: "Lebanese Pound", number: 422},
    // {code: "LKR", name: "Sri Lanka Rupee", number: 144},
    // {code: "LRD", name: "Liberian Dollar", number: 430},
    // {code: "LSL", name: "Loti", number: 426},
    // {code: "LYD", name: "Libyan Dinar", number: 434},
    // {code: "MAD", name: "Moroccan Dirham", number: 504},
    // {code: "MAD", name: "Moroccan Dirham", number: 504},
    // {code: "MDL", name: "Moldovan Leu", number: 498},
    // {code: "MGA", name: "Malagasy Ariary", number: 969},
    // {code: "MKD", name: "Denar", number: 807},
    // {code: "MMK", name: "Kyat", number: 104},
    // {code: "MNT", name: "Tugrik", number: 496},
    // {code: "MOP", name: "Pataca", number: 446},
    // {code: "MRU", name: "Ouguiya", number: 929},
    // {code: "MUR", name: "Mauritius Rupee", number: 480},
    // {code: "MVR", name: "Rufiyaa", number: 462},
    // {code: "MWK", name: "Kwacha", number: 454},
    {code: "MXN", name: "Mexican Peso", number: 484},
    // {code: "MXV", name: "Mexican Unidad de Inversion (UDI)", number: 979},
    {code: "MYR", name: "Malaysian Ringgit", number: 458},
    // {code: "MZN", name: "Mozambique Metical", number: 943},
    // {code: "NAD", name: "Namibia Dollar", number: 516},
    // {code: "NGN", name: "Naira", number: 566},
    // {code: "NIO", name: "Cordoba Oro", number: 558},
    {code: "NOK", name: "Norwegian Krone", number: 578},
    // {code: "NPR", name: "Nepalese Rupee", number: 524},
    {code: "NZD", name: "New Zealand Dollar", number: 554},
    // {code: "OMR", name: "Rial Omani", number: 512},
    // {code: "PAB", name: "Balboa", number: 590},
    // {code: "PEN", name: "Nuevo Sol", number: 604},
    // {code: "PGK", name: "Kina", number: 598},
    {code: "PHP", name: "Philippine Peso", number: 608},
    // {code: "PKR", name: "Pakistan Rupee", number: 586},
    {code: "PLN", name: "Zloty", number: 985},
    // {code: "PYG", name: "Guarani", number: 600},
    // {code: "QAR", name: "Qatari Rial", number: 634},
    {code: "RON", name: "Romanian Leu", number: 946},
    // {code: "RSD", name: "Serbian Dinar", number: 941},
    {code: "RUB", name: "Russian Ruble", number: 643},
    // {code: "RWF", name: "Rwanda Franc", number: 646},
    // {code: "SAR", name: "Saudi Riyal", number: 682},
    // {code: "SBD", name: "Solomon Islands Dollar", number: 90},
    // {code: "SCR", name: "Seychelles Rupee", number: 690},
    // {code: "SDG", name: "Sudanese Pound", number: 938},
    {code: "SEK", name: "Swedish Krona", number: 752},
    {code: "SGD", name: "Singapore Dollar", number: 702},
    // {code: "SHP", name: "Saint Helena Pound", number: 654},
    // {code: "SLE", name: "Leone", number: 925},
    // {code: "SOS", name: "Somali Shilling", number: 706},
    // {code: "SRD", name: "Surinam Dollar", number: 968},
    // {code: "SSP", name: "South Sudanese Pound", number: 728},
    // {code: "STN", name: "Dobra", number: 930},
    // {code: "SVC", name: "El Salvador Colon", number: 222},
    // {code: "SYP", name: "Syrian Pound", number: 760},
    // {code: "SZL", name: "Lilangeni", number: 748},
    {code: "THB", name: "Thai Baht", number: 764},
    // {code: "TJS", name: "Somoni", number: 972},
    // {code: "TMT", name: "Turkmenistan New Manat", number: 934},
    // {code: "TND", name: "Tunisian Dinar", number: 788},
    // {code: "TOP", name: "Pa’anga", number: 776},
    {code: "TRY", name: "Turkish Lira", number: 949},
    // {code: "TTD", name: "Trinidad and Tobago Dollar", number: 780},
    // {code: "TWD", name: "New Taiwan Dollar", number: 901},
    // {code: "TZS", name: "Tanzanian Shilling", number: 834},
    // {code: "UAH", name: "Hryvnia", number: 980},
    // {code: "UGX", name: "Uganda Shilling", number: 800},
    {code: "USD", name: "US Dollar", number: 840},
    // {code: "UYI", name: "Uruguay Peso en Unidades Indexadas (URUIURUI)", number: 940},
    // {code: "UYU", name: "Peso Uruguayo", number: 858},
    // {code: "UZS", name: "Uzbekistan Sum", number: 860},
    // {code: "VED", name: "Bolivar", number: 926},
    // {code: "VEF", name: "Bolivar", number: 937},
    // {code: "VND", name: "Dong", number: 704},
    // {code: "VUV", name: "Vatu", number: 548},
    // {code: "WST", name: "Tala", number: 882},
    // {code: "XAF", name: "CFA Franc BEAC", number: 950},
    // {code: "XCD", name: "East Caribbean Dollar", number: 951},
    // {code: "XDR", name: "SDR (Special Drawing Right)", number: 960},
    // {code: "XOF", name: "CFA Franc BCEAO", number: 952},
    // {code: "XPF", name: "CFP Franc", number: 953},
    // {code: "XSU", name: "Sucre", number: 994},
    // {code: "XUA", name: "ADB Unit of Account", number: 965},
    // {code: "YER", name: "Yemeni Rial", number: 886},
    {code: "ZAR", name: "South African Rand", number: 710},
    // {code: "ZMW", name: "Zambian Kwacha", number: 967},
    // {code: "ZWL", name: "Zimbabwe Dollar", number: 932},
];