import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER, SEMICOLON, TAB} from "@angular/cdk/keycodes";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {WarehouseLocationFormComponent} from "../../../../locations";
import {debounceTime, takeUntil} from "rxjs/operators";
import {ReplaySubject} from "rxjs";
import {User} from "../../../../../../../../common/interfaces/user.interface";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {UserService} from "../../../../../../../../common/services/user.service";

@Component({
    selector: "section-warehouse-procedures-wizard-transfer-update-serial",
    templateUrl: "update-serial.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardTransferUpdateSerialHubComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    @ViewChild(MatAutocompleteTrigger, {static: false})
    public autocompleteTrigger: MatAutocompleteTrigger;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    private state: Base.IState;

    public warehouse: Warehouse.IWarehouse;

    public separatorKeysCodes: any[] = [ENTER, COMMA, SEMICOLON, TAB];

    public subs: any[] = [];

    public serials: string[] = [];

    public locationSearchInput: FormControl = new FormControl(null);

    public partners: any[] = [];

    public partnerSelect: FormControl = new FormControl();

    public transferForm: FormGroup = new FormGroup({
        serials: new FormControl([], [Validators.required]),
        partner_id: new FormControl(null, [Validators.required]),
        sub_inventory: new FormControl(null),
    });

    public scanType: string;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
        super(changeDetectorRef);
    }


    /**
     * Get all related sub-warehouses
     */
    private async getPartners(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "partners"]);
        if (data) {
            this.partners = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Get all related sub-warehouses
     */
    private async getSubWarehouses(partner: string): Promise<any> {
        if (!this.userService.validatePermissions("browse_admin")) {
            return;
        }

        this.spinnerService.show();

        this.apiService.setHeaders({Partner: partner});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "sub"]);
        if (data) {
            this.subs = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Add serials to chips
     * @param event
     * @param configuration
     */
    public async addSerials(event: MatChipInputEvent, configuration: string = null): Promise<any> {
        this.spinnerService.show();
        const value: string = event.value.trim();

        if (event.input) {
            event.input.value = "";
            this.changeDetectorRef.markForCheck();
        }

        if (value) {
            const serials: string[] = value.split(/[\s|\t|;|,]/);
            const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                ["inventory", "serials"], {serials});

            if (data) {
                data.forEach((serial: { serial: string }): void => {
                    this.serials.push(serial.serial);
                });
                this.transferForm.get("serials").setValue(this.serials);
            }
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove serial chip
     * @param serial
     */
    public removeSerial(serial: string): void {
        const index: number = this.serials.indexOf(serial);
        if (index >= 0) {
            this.serials.splice(index, 1);
            this.transferForm.get("serials").setValue(this.serials);
        }
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        const text = "If serial item has a box, then the box will be unlinked."
            + " Do you want to update the serial item?";
        if (!await this.confirmRef.confirm(text)) {
            return;
        }
        if (this.locationSearchInput.value) {
            this.transferForm.get("warehouse_location_id").setValue(this.locationSearchInput.value.id);
        }
        this.spinnerService.show();
        const {type, message, data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "serials"], this.transferForm.value);
        if (type as string === "success") {
            this.toastService.show(message + "<br>" + data.join("<br>"), "success");
            this.serials = [];
            this.subs = [];
            this.transferForm.reset();
            this.partnerSelect.reset();
            this.locationSearchInput.reset();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.warehouse = data.warehouse;

        this.result.emit({
            action: "result",
            value: true
        });

        this.getPartners();

        this.partnerSelect.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: User.IPartner): void => {
                if (value) {
                    this.transferForm.get("partner_id").setValue(value.id);
                    this.getSubWarehouses(value.slug);
                }
            });
    }

    displayFn(option: any): string {
        let displayOption: string;
        if (option && option.location) {
            displayOption = option.location + " ( " + option.warehouse_location_type.name + " ) - ";
            if (option.inventory) {
                displayOption += option.inventory.inventory_conversion.gb;
            } else {
                displayOption += "empty";
            }
        } else {
            displayOption = "";
        }
        return displayOption;
    }

    /**
     * Redirect to order on dropdown selected option
     * @param {MatAutocompleteSelectedEvent} $event
     */
    public onOptionSelected($event: MatAutocompleteSelectedEvent): void {
        if ($event.option.value) {
            this.transferForm.get("warehouse_location_id").setValue($event.option.value.id);
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
