import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Api} from "../../../../../../common/services/api.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {UserService} from "../../../../../../common/services/user.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {Router} from "@angular/router";
import {CURRENCIES} from "../../../../../../common/constants/currencies";

@Component({
    selector: "section-currencies-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Currencies list</h1>
        <mat-card>
            <div>
                <common-table2 #listTableRef *ngIf="listTable" [settings]="listTable">
                    <div row1 class="row flex align-center">
                        <button mat-raised-button color="primary" class="main margin-right-10"
                                (click)="showAddForm()">
                            Add
                        </button>
                    </div>
                </common-table2>
            </div>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyListComponent implements OnInit, Base.IComponent {

    private world_currencies: any = {};

    public readonly state: Base.IState;

    public listTable: Table.ISettings;


    @ViewChild("listTableRef", {static: false})
    public listTableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private router: Router,
    ) {
    }


    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "currtbl",
            actions: [
                {
                    name: "view",
                    title: "View",
                    click: (row: any): void => {
                        this.showEditForm(row);
                    },
                    disabledFn: (row: any): boolean => {
                        return !this.userService.validatePermissions("edit_currency");
                    }
                },
                {
                    name: "delete",
                    title: "Delete",
                    click: (row: any): void => {
                        this.delete(row);
                    },
                    disabledFn: (row: any): boolean => {
                        return !this.userService.validatePermissions("delete_currency");
                    }
                }
            ],
            api: {
                url: [this.state.section, "currencies"],
                version: 3
            },
            columns: [
                {
                    data: "code",
                    title: "Code"
                },
                {
                    data: "code",
                    title: "Name",
                    render: (row: any): string => {
                        if (this.world_currencies.hasOwnProperty(row.code)) {
                            return this.world_currencies[row.code];
                        }
                        return "";
                    },
                    sortable: false,
                    searchable: false,
                },
                {
                    data: "created_at",
                    title: "Created at"
                },
                {
                    data: "updated_at",
                    title: "Updated at"
                },
            ],
        };
        this.changeDetectorRef.markForCheck();
    }


    private async showEditForm(currency: any): Promise<any> {
        this.router.navigate([
            this.state.section,
            "currencies",
            "edit",
            "code",
            currency.code
        ]);
    }


    public async delete(currency: any): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete current address?")) {

            this.spinnerService.show();
            const {message, code}: Api.IResponse = await this.api3Service.delete(
                `${this.state.section}/currencies/${currency.code}`);
            this.spinnerService.hide();

            if (code === 200) {
                this.toastService.show(message, "success");
                this.listTableRef.reload();
            }
        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public async showAddForm(): Promise<any> {
        this.router.navigate([
            this.state.section,
            "currencies",
            "add",
        ]);
    }

    public ngOnInit(): void {
        for (const c of CURRENCIES) {
            this.world_currencies[c.code] = c.name;
        }

        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "currencies",
            actions: {
                "browse": ["browse_currency"]
            }
        };
    }
}
