import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {Api} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {CURRENCIES} from "../../../../../../common/constants/currencies";
import {Router} from "@angular/router";

@Component({
    selector: "section-currency-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CurrencyFormComponent implements OnInit, OnDestroy, Base.IComponent {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public formGroup: FormGroup = new FormGroup({
        code: new FormControl(null, [Validators.required]),
        rates: new FormArray([]),
    });

    public world_currencies: ISelectOption[] = [];

    public currencies: ISelectOption[] = [];

    public get rates(): FormArray {
        return this.formGroup.get("rates") as FormArray;
    }

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private api3Service: Api3Service,
        private router: Router
    ) {
    }

    private async getCurrencies(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/currencies`, {
            data_structure: "select"
        });
        this.spinnerService.hide();

        if (data) {
            for (const world_currency of this.world_currencies) {
                for (const existing_currency of data) {
                    if (existing_currency.value == world_currency.value
                        && world_currency.value != (this.state.params.code || null)) {
                        world_currency.disabled = true;
                    }
                }
            }

            this.changeDetectorRef.markForCheck();

            if (!this.state.params.code) {
                return;
            }

            for (const existing_currency of data) {
                if (existing_currency.value == this.state.params.code) {
                    continue;
                }

                this.rates.push(
                    new FormGroup({
                        code: new FormControl(existing_currency.value),
                        rate: new FormControl(1, [Validators.required, Validators.min(0.001)]),
                        autoupdate: new FormControl(false),
                    }));
            }

            this.changeDetectorRef.detectChanges();

            this.getData();
        }
    }

    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/currencies/${this.state.params.code}`);
        this.spinnerService.hide();

        if (data) {
            this.formGroup.get("code").setValue(data.code);

            for (const rate_form of this.rates.controls) {

                for (const rate of data.rates) {
                    if (rate.code == rate_form.value.code) {
                        rate_form.get("rate").setValue(rate.rate || 1);
                        rate_form.get("autoupdate").setValue(!!rate.autoupdate);
                    }
                }
            }

            this.changeDetectorRef.markForCheck();
        }
    }

    private async create(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.post(`${this.state.section}/currencies`,
            this.formGroup.value);

        this.spinnerService.hide();

        if (data) {
            this.router.navigate([
                this.state.section,
                "currencies",
                "edit",
                "code",
                data.code
            ]);
        }

    }

    private async update(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.put(
            `${this.state.section}/currencies/${this.state.params.code}`, this.formGroup.value);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public async handleFormSubmit(): Promise<any> {
        if (!this.formGroup.valid) {
            return;
        }
        if (this.state.params.code) {
            this.update();
        } else {
            this.create();
        }
    }

    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this currency?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.api3Service.delete(
                `${this.state.section}/currencies/${this.state.params.data.code}`);
            this.spinnerService.hide();

            if (type as string === "success") {
                this.toastService.show(message, "success");

                this.showList();
            }
        }
    }

    public showList(): void {
        this.router.navigate([
            this.state.section,
            "currencies"
        ]);
    }

    public ngOnInit(): void {
        this.world_currencies = CURRENCIES.map((c): ISelectOption => {
            return {name: `${c.code} (${c.name})`, value: c.code};
        });

        this.getCurrencies();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "currencies",
            actions: {
                "add": ["add_currency"],
                "edit": ["edit_currency"]
            }
        };
    }
}
