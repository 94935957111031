import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {TrackingSidebarComponent} from "../sidebar/sidebar.component";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {FilterComponent} from "../../../../../../common/components/filter/filter.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {StorageService} from "../../../../../../common/services/storage.service";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {CourierTransactionDeliveredFormComponent} from "../../../../threepl/warehouse/courier-transaction";
import {TrackingStatusComponent} from "../status/status.component";
import * as moment from "moment-timezone";

@Component({
    selector: "section-tracking-list",
    templateUrl: "list.component.html",
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TrackingListComponent implements Base.IComponent, OnInit {

    /**
     * Is table ready?
     * @type {boolean}
     */
    private ready: boolean = false;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public readonly state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(FilterComponent, {static: false})
    public filterRef: FilterComponent;

    @ViewChild(TrackingSidebarComponent, {static: false})
    public sidebarRef: TrackingSidebarComponent;

    public showFilter: boolean = false;

    /**
     * Filters list
     */
    public filters: { [key: string]: any };

    public showExportButton: boolean = false;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private storageService: StorageService,
    ) {
    }

    private getTableUrl(query: any[] = []): Table.ITableApi {
        return {
            url: [
                this.state.section,
                "courier-transactions"
            ],
            query: {
                ...query,
                relations: [
                    "Order:id,ref,created_at,service_level_id,order_status_id",
                    "Order.ServiceLevel:id,name,order_type_id",
                    "Order.ServiceLevel.orderType",
                    "Order.Status:id,name",
                    "Courier:id,slug,display_name",
                    "CourierService:id,slug,service_name",
                    "Shipment:id,address_to,address_from,type",
                    "Shipment.FromAddress:id,country,country_iso_2,lat,lng",
                    "Shipment.ToAddress:id,country,country_iso_2,zip,lat,lng",
                    "Shipment.FromAddress.CountryDetailed:country_iso_2,timezone",
                    "Shipment.ToAddress.CountryDetailed:country_iso_2,timezone",
                    "Partner:id,display_name,icon_path,slug",
                    "ShippyProPickup",
                    "Shipment.WarehousePackages:id,shipment_id,gross_weight"
                ],
            },
            version: 3
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(query: any[] = []): void {
        const actions: Table.IAction[] = [
            {
                name: "concierge",
                title: "Pickup",
                click: (row: any): void => {
                    this.router.navigate([
                        this.state.section,
                        "shippy-pickup",
                        "add",
                        "order",
                        row.order_id,
                        "shipment",
                        row.shipment_id,
                        "track",
                        row.id,
                        "back_to",
                        btoa(this.router.url)
                    ]);
                },
                disabledFn: (row: any): boolean => {
                    return !(this.userService.validatePermissions("create_shippy_pro_pickups")
                        && row.params?.shippy_pro_carrier_id && !row.shippy_pro_pickup);

                }
            }
        ];
        const columns: Table.ICol[] = [
            {
                data: "status",
                title: "Track status",
                sortable: false,
                render: (row: any): string => {
                    let icon: string;
                    switch (row.status) {
                        case "delivered":
                            icon = "check_circle";
                            break;
                        case "out for delivery":
                            icon = "local_shipping";
                            break;
                        case "exception":
                            icon = "error";
                            break;
                        default:
                            icon = "radio_button_checked";
                    }
                    return "<mat-icon class='track-status mat-icon material-icons " + row.status + "'>"
                        + icon
                        + "</mat-icon> " + row.status;
                }
            },
            {
                data: "order.ref",
                name: "Order.ref",
                title: "Order",
                sortable: false,
                render: (row: any): string => {
                    return row.order ? "<span class='link'>" + row.order.ref + "</span>" : "";
                },
                click: (row: any): void => {
                    if (row.order && this.state.section_type == "partner") {
                        this.router.navigate([
                            "/partner", row.partner.slug, "orders", "view", "id", row.order.id
                        ]);
                    }
                }
            },
            {
                data: "order.service_level.name",
                name: "Order.ServiceLevel.name",
                title: "Service level",
                sortable: false,
            },
            {
                data: "id",
                title: "",
                sortable: false,
                render: (row: any): string => {
                    if (this.state.section_type == "partner") {
                        return row.order ? `<span class="mat-icon material-icons pointer">open_in_new</span>` : "";
                    } else {
                        return "";
                    }
                },
                click: (row: any): void => {
                    if (row.order && this.state.section_type == "partner") {
                        const slug: string = row.partner.slug;
                        const ref: string = row.order.id;
                        window.open(`/partner/${slug}/orders/view/id/${ref}`, "_blank");
                    }
                },
                searchable: false,
                cssClass: "action"
            },
            {
                data: "order.status.name",
                name: "Order.Status.name",
                title: "Order status",
                sortable: false,
            },
            {
                data: "tracking_number",
                title: "Tracking number",
                render: (row: any): string => {
                    return "<span class='link'>" + row.tracking_number + "</span>";
                },
                click: (row: any): void => {
                    if (this.state.section_type == "partner") {
                        this.sidebarRef.show(row.id);
                    }
                }
            },
            {
                data: "courier.display_name",
                name: "Courier.display_name",
                title: "Courier",
                sortable: false,
            },
            {
                data: "courier_service.service_name",
                name: "CourierService.service_name",
                title: "Courier Service",
                sortable: false,
            },
            {
                data: "shipment.from_address.country",
                name: "Shipment.FromAddress.country",
                title: "Origin",
                sortable: false,
            },
            {
                data: "shipment.to_address.country",
                name: "Shipment.ToAddress.country",
                title: "Destination",
                sortable: false,
            },
            {
                data: "shipment.to_address.zip",
                name: "Shipment.ToAddress.zip",
                title: "Destination postal code",
                sortable: false,
            },
            {
                data: "id",
                title: "Distance, km",
                render: (row: any): string => {
                    if (!row.shipment.from_address || !row.shipment.to_address) {
                        return "";
                    }
                    return "" + HelpersService.getDistanceKmByCoords(
                        row.shipment.from_address.lat,
                        row.shipment.from_address.lng,
                        row.shipment.to_address.lat,
                        row.shipment.to_address.lng
                    );
                },
                exportable: false
            },
            {
                data: "id",
                title: "Weight",
                render: (row: any) => {
                    return row.shipment.warehouse_packages.map(p => p.gross_weight)
                        .reduce((accum, current) => {
                            return accum + current;
                        }, 0);
                },
                exportable: false
            },
            {
                data: "shipped_at",
                name: "shipped_at",
                title: "Shipped at",
                searchable: false,
                render: (row: any) => {
                    if (!row.shipped_at || !row.shipment.from_address) {
                        return "";
                    }

                    if (!row.shipment.from_address?.country_detailed?.timezone) {
                        return row.shipped_at;
                    }

                    return moment.tz(
                        row.shipped_at,
                        "YYYY-MM-DD HH:mm:ss",
                        "Asia/Jerusalem")
                        .tz(row.shipment.from_address.country_detailed.timezone)
                        .format("YYYY-MM-DD HH:mmZ");
                }
            },
            {
                data: "pickup_date",
                name: "pickup_date",
                title: "Pickup date",
                searchable: false,
                render: (row: any) => {
                    if (!row.pickup_date || !row.shipment.from_address) {
                        return "";
                    }

                    if (!row.shipment.from_address?.country_detailed?.timezone) {
                        return row.pickup_date;
                    }

                    return moment.tz(
                        row.pickup_date,
                        "YYYY-MM-DD HH:mm:ss",
                        "Asia/Jerusalem")
                        .tz(row.shipment.from_address.country_detailed.timezone)
                        .format("YYYY-MM-DD HH:mmZ");
                }
            },
            {
                data: "delivered_at",
                name: "delivered_at",
                title: "POD date",
                searchable: false,
                render: (row: any) => {
                    if (!row.delivered_at || !row.shipment.to_address) {
                        return "";
                    }

                    if (!row.shipment.to_address?.country_detailed?.timezone) {
                        return row.delivered_at;
                    }

                    return moment.tz(
                        row.delivered_at,
                        "YYYY-MM-DD HH:mm:ss",
                        "Asia/Jerusalem")
                        .tz(row.shipment.to_address.country_detailed.timezone)
                        .format("YYYY-MM-DD HH:mmZ");
                }
            },
            {
                data: "order.created_at",
                name: "Order.created_at",
                title: "Order created at",
                sortable: false,
                searchable: false
            },
        ];

        if (this.userService.validatePermissions("see_tracking_price")) {
            columns.splice(5, 0, {
                data: "price",
                title: "Price",
                searchable: false
            }, {
                data: "charged_on",
                title: "Charged on",
                searchable: false
            });
        }

        if (this.state.section_type !== "partner") {
            columns.unshift({
                data: "partner.display_name",
                title: "Partner",
                render: (row: any): string => {
                    return "<img src='" + row.partner.icon_path + "' alt=''>";
                },
            });
        }

        if (this.state.section_type === "warehouse") {
            actions.push({
                name: "moved_location",
                title: "Status",
                click: (row: any): void => {
                    if (["available for pickup", "pending", "info received"].includes(row.status) && !row.pickup_date) {

                        row.location = row.shipment.type === "outbound"
                            ? row.shipment.from_address.country_iso_2
                            : row.shipment.to_address.country_iso_2;

                        const timezone = row.shipment.type === "outbound"
                            ? row.shipment?.from_address?.country_detailed?.timezone :
                            row.shipment?.to_address?.country_detailed?.timezone;

                        this.modalService.open(TrackingStatusComponent, {
                            data: row,
                            modalWidth: "600px",
                            statuses: [
                                "Out for Delivery",
                                "Delivered",
                            ],
                            file_upload: true,
                            timezone,

                        }).then(() => {
                            this.tableRef.reload(this.getTableUrl(this.buildFilterQuery()));
                        });
                    } else if (row.status !== "delivered" && row.pickup_date) {
                        this.modalService.open(CourierTransactionDeliveredFormComponent, {id: row.id})
                            .then(() => {
                                this.tableRef.reload(this.getTableUrl(this.buildFilterQuery()));
                            });
                    }
                },
                disabledFn: (row: any): boolean => {
                    if (["available for pickup", "pending", "info received"].includes(row.status) && !row.pickup_date) {
                        return false;
                    } else if (row.status !== "delivered" && row.pickup_date) {
                        return false;
                    }
                    return true;
                }
            });
        }

        this.listTable = {
            table_id: "57nY0jtT",
            actions,
            api: this.getTableUrl(query),
            columns,
            export: {
                file_name: "track_list_export",
            },
            search_default: ["tracking_number"]
        };
        this.changeDetectorRef.markForCheck();
    }

    private buildFilterQuery(filters = null): any {
        if (filters) {
            this.filters = filters;
        }

        const params: { [key: string]: any[] } = {};
        if (this.filters) {
            for (const filter of Object.keys(this.filters)) {
                params[filter] = [];
                for (const option of this.filters[filter]) {
                    if (option.selected) {
                        params[filter].push(option.value);
                    }
                }
            }
        }
        return params;
    }

    /**
     * Get data from api
     */
    public getData(filters: any = null): void {
        const filter_query: any = this.buildFilterQuery(filters);

        this.filters = null;
        this.changeDetectorRef.markForCheck();

        this.api3Service.get(this.state.section + "/courier-transactions/filters", filter_query)
            .then(({data}: Api.IResponse): void => {
                this.filters = data;

                this.changeDetectorRef.markForCheck();

                this.storageService.set("Filters_" + window.location.pathname, this.filters);

                if (this.ready) {
                    this.tableRef.reload(this.getTableUrl(this.buildFilterQuery()));
                } else {
                    this.prepareList(this.buildFilterQuery());
                    this.ready = true;
                }
                this.spinnerService.hide();
            });
    }

    public reload(): void {
        this.tableRef.reload(this.getTableUrl());
    }

    public async report(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            configUrl: ["report", "shipments-list"],
            submitUrl: ["report", "shipments-list"],
            asyncKey: HelpersService.randomString()
        });
    }

    public toggleFilter(): void {
        this.storageService.set("showFilter", !this.showFilter);
        this.showFilter = this.storageService.get("showFilter");
    }

    public importPrice(): void {
        AmplitudeService.eventClick("Update prices (tracking)");
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "tracking_price",
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public update(): void {
        this.tableRef.reload();
    }

    public ngOnInit(): void {

        this.filters = this.storageService.get("Filters_" + window.location.pathname);
        this.getData();

        this.showFilter = this.storageService.get("showFilter", false);
        this.changeDetectorRef.markForCheck();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "tracking-list",
            actions: {
                "browse": ["browse_courier_transactions"]
            }
        };
    }


}
